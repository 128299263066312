<template>
  <div>
    <p class="p-b-5">
      <Button type="primary" size="small" @click="confirmReconcilliation" v-if="isAuth('reconciliation_transaction_confirm')&&!autoConfirm"
          :disabled="feeitemIds.length===0||notAllowReconciliationQuantity > 0">批量确认对账</Button>
      <Button type="success" size="small" class="m-l-5" @click="showDownLoadProgrammeModal = true">下载对账清单</Button>

      <span class="m-l-50 title">
        PS：交易对账操作仅可以完成 <span class="text-orange">上月(含)以前</span> 的数据对账！
      </span>
    </p>
    <Table :loading="loading"
         stripe :data="list" :columns="tableColumns" @on-selection-change="selectionChange"
        :height="clientHeight"
        :summary-method="handleSummary"
        show-summary></Table>
    <Row>
      <i-col span='12'>
        <span style="height: 28px;line-height: 28px;">已选中{{feeitemIds.length}}条</span>
      </i-col>
      <i-col  span='12'>
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            show-sizer
            :page-size-opts="[15,50,100,200,500]"
            :current="query.pageNumber"
            @on-change="handlePageChanged"
            @on-page-size-change="handlePageSizeChanged"></Page>
        </div>
      </i-col>
    </Row>

    <Modal v-model="showDownLoadProgrammeModal">
      <h3 class="p-b-10" slot="header">请选择下载类型</h3>
      <Row>
        <i-col span="24">
          <RadioGroup v-model="downLoadProgramme" vertical>
            <Radio label="1">
                <span>全部下载</span>
            </Radio>
            <Radio label="2">
                <span>勾选下载</span>
            </Radio>
        </RadioGroup>
        </i-col>
      </Row>

      <div slot="footer">
        <Button type="text" @click="showDownLoadProgrammeModal=false">取消</Button>
        <Button type="primary"  @click="choiceDownLoadProgramme">确认</Button>
      </div>
    </Modal>

    <Modal v-model="modalContract" :width="1200" footer-hide>
      <contract-detail v-if="modalContract" :id="contractId"  :versionId="contractId"></contract-detail>
    </Modal>
  </div>
</template>

<script>
import ContractDetail from '@/components/contract/contractDetail/Contract'

import { toMoney } from '@/utils/wnumb_own'
import { GetPublishDay, GetCurrentSchedule, ParseDate, ParseDateForMonth, getMonth } from '@/utils/dateFormat'
import { formatReconcilliationStatus } from '@/utils/tagStatus'
import { downloadFileRequest } from '@/utils/download'

// import { confirmfeeitems } from '@/api/statement/bill'
// import { countContractAmount } from '@/api/dw/verify'

import { listContractConfirmInfo, listContractConfirmAmount, confirmContractFee } from '@/api/statement/confirm'

export default {
  components: {
    ContractDetail
  },
  data () {
    return {

      autoConfirm: this.$store.getters.token.publisherSetting.allowAutoConfirmContractExecutedAmount, // 是否开启自动对账
      clientHeight: 0,
      total: 0,
      loading: false,

      query: {
        pageNumber: 1,
        pageSize: 15,
        companyId: 0,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        startDate: '',
        endDate: '',
        executeStatus: null,
        queryString: ''
      },
      list: [],
      tableColumns: [
        {
          type: 'selection',
          key: 'feeItemId',
          width: 60,
          align: 'center'
        },
        {
          title: '代理商',
          key: 'companyName'
        },
        {
          title: '所属销售',
          key: 'userName'
        },
        {
          title: '合同编号',
          key: 'code',
          render: (h, params) => {
            return h('a',
              {
                on: {
                  click: () => {
                    this.contractId = params.row.contractId
                    this.modalContract = true
                  }
                }
              }, params.row.code)
          }
        },
        {
          title: '合同名称',
          key: 'name'
        },
        {
          title: '客户',
          key: 'advertiserName'
        },
        {
          title: '合同起止日',
          key: 'startDate',
          render: (h, params) => {
            return h('div', [h('span', this.formatSchuedle(params.row).value)])
          }
        },

        {
          title: '对账月份',
          key: 'executeDate',
          render: (h, params) => {
            return h('div', [h('span', getMonth(params.row.executeDate))])
          }
        },
        {
          title: '合同总额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        {
          title: '当前周期执行额',
          align: 'center',
          children: [
            {
              title: '媒介使用费',
              align: 'center',
              key: 'usePrice',
              render: (h, param) => {
                return h('span', {
                  class: 'money'
                }, toMoney(param.row.usePrice))
              }
            },
            {
              title: '服务费',
              align: 'center',
              key: 'servicePrice',
              render: (h, param) => {
                return h('span', {
                  class: 'money'
                }, toMoney(param.row.servicePrice))
              }
            }
          ]
        },
        {
          title: '状态',
          key: 'executeStatus',
          width: 120,
          render: (h, data) => {
            return formatReconcilliationStatus(
              h,
              data.row.confirmStatus,
              data.row.confirmStatus === '01' ? '部分对账' : (data.row.confirmStatus === '1' ? '已对账' : '未对账')
            )
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  this.$store.commit('set_reconciliationDemo_detailBean', params.row)
                  this.$store.commit('set_reconciliationDemo_pageType', 'Detail')
                }
              }
            }, '详情')
          }
        }
      ],

      feeitemIds: [], // 页面选中的所有项
      currentPageAllItemIds: [], // 当前操作的页面所有项的主键集合
      currentPageSelectionIdArray: [],

      showDownLoadProgrammeModal: false,
      downLoadProgramme: '1',

      summaryData: null,
      modalContract: false,
      contractId: 0
    }
  },
  created () {
    this.clientHeight = document.documentElement.clientHeight - 220
    this.query.startDate = '2020-01-01'
    this.query.endDate = new Date().getFullYear() + '-12-31'
    this.feeitemIds = []
    this.$store.commit('set_reconciliationDemo_notAllowReconciliationQuantity', 0)
    this.loadSummaryData()
  },
  methods: {
    loadSummaryData () {
      listContractConfirmAmount(this.query).then(res => {
        this.summaryData = res
        this.loadPage()
      })
    },
    loadPage () {
      this.loading = true
      const that = this
      listContractConfirmInfo(that.query).then((res) => {
        if (res.list.length) {
          res.list.map((item) => {
            item.visible = item.visibleScope === 2
            item._checked = that.feeitemIds.includes(item.feeItemId)
            return item
          })
          that.currentPageAllItemIds = res.list.map(item => {
            return item.feeItemId
          })
        }

        that.list = res.list
        that.total = res.totalRow
        that.loading = false

        // 如果当前是最后一页，同时没有数据，那么重新加载上一页
        if (res.totalRow > 0 && res.list.length === 0) {
          that.query.pageNumber -= 1
          that.loadPage()
        }
      })
    },
    handleSummary ({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '总计'
          }
          return
        }
        if (key === 'amount') {
          sums[key] = {
            key,
            value: toMoney(this.summaryData.totalPrice)
          }
        } else if (key === 'usePrice') {
          sums[key] = {
            key,
            value: toMoney(this.summaryData.usePrice)
          }
        } else if (key === 'servicePrice') {
          sums[key] = {
            key,
            value: toMoney(this.summaryData.servicePrice)
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })
      return sums
    },
    formatSchuedle (data) {
      return {
        value: GetCurrentSchedule(data.startDate, data.endDate),
        days: GetPublishDay(data.startDate, data.endDate)
      }
    },
    formatMoney (number) {
      return toMoney(number)
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.loadPage()
    },
    handlePageSizeChanged (pagesize) {
      this.query.pageSize = pagesize
      this.query.pageNumber = 1
      this.loadPage()
    },
    selectionChange (selection) {
      const that = this
      var quantity = that.notAllowReconciliationQuantity
      const selectionIds = selection.map(item => {
        return item.feeItemId
      })
      // 循环当前页面所有列
      that.currentPageAllItemIds.forEach(item => {
        // 列是否是选中状态

        const currentItem = that.list.filter(x => x.feeItemId === item)[0]
        if (selectionIds.includes(item)) {
          // 判断是否已在列表，不在则加入
          if (!that.feeitemIds.includes(item)) {
            that.feeitemIds.push(item)
            if (currentItem.exeFlag === 0) {
              quantity = quantity + 1
            }
          }
        } else { // 列未被选中
          // 判断是否已在列表，在则移除
          if (that.feeitemIds.includes(item)) {
            that.feeitemIds.splice(that.feeitemIds.indexOf(item), 1)
            if (currentItem.exeFlag === 0) {
              quantity = quantity - 1
            }
          }
        }
      })
      this.$store.commit('set_reconciliationDemo_notAllowReconciliationQuantity', quantity)
    },
    confirmReconcilliation () {
      const that = this
      if (!that.feeitemIds.length) {
        that.$Notice.info({ desc: '请选择要对账的条目！' })
      } else {
        that.$Modal.confirm({
          title: '操作确认',
          content: '<p>是否要将所选条目标记为已对账，确认后<span style="color:red;font-weight:600;">无法再次变更</span>，请谨慎操作！</p>',
          loading: true,
          onOk: () => {
            const query = {
              feeItemIds: that.feeitemIds.join(',')// JSON.stringify(that.feeitemIds),
              // startDate: that.transactionReconciliationQuery.startDate,
              // endDate: that.transactionReconciliationQuery.endDate,
              // feeitemType: 1
            }
            // if (that.transactionReconciliationQuery.startDate) {
            //   query.startDate = that.transactionReconciliationQuery.startDate
            // }

            // 验证结束时间是否有值，无值则给默认值（上个月）
            // const prevMonth = GetPrevMonth(new Date())
            // if (that.transactionReconciliationQuery.endDate && (new Date(that.transactionReconciliationQuery.endDate) <= new Date(prevMonth))) {
            //   query.endDate = that.transactionReconciliationQuery.endDate
            // } else {
            //   query.endDate = prevMonth
            // }

            confirmContractFee(query).then(res => {
              if (res && !res.errcode) {
                this.$Notice.success({ desc: '成功提交对账！' })
                that.feeitemIds = []
                that.$store.commit('set_reconciliationDemo_update', new Date())
              }
              that.$Modal.remove()
            }).catch(() => {
              that.$Modal.remove()
            })
          }
        })
      }
    },
    choiceDownLoadProgramme () {
      const that = this
      const params = {}
      if (parseInt(that.downLoadProgramme) === 1) {
        params.companyId = that.transactionReconciliationQuery.companyId
        params.startDate = that.transactionReconciliationQuery.startDate
        params.endDate = that.transactionReconciliationQuery.endDate
        params.queryString = that.transactionReconciliationQuery.queryString
        if (that.transactionReconciliationQuery.executeStatus !== -1) {
          params.executeStatus = that.transactionReconciliationQuery.executeStatus
        }

        params.publisherId = this.query.publisherId
      } else {
        if (!that.feeitemIds.length) {
          that.$Notice.info({ desc: '请选择要下载的条目！' })
          return
        } else {
          params.feeItemIds = that.feeitemIds.join(',')
          params.startDate = that.transactionReconciliationQuery.startDate
          params.endDate = that.transactionReconciliationQuery.endDate
          // // 验证结束时间是否有值，无值则给默认值（上个月）
          // const prevMonth = GetPrevMonth(new Date())
          // if (that.transactionReconciliationQuery.endDate && (new Date(that.transactionReconciliationQuery.endDate) <= new Date(prevMonth))) {
          //   params.endDate = that.transactionReconciliationQuery.endDate
          // } else {
          //   params.endDate = prevMonth
          // }
        }
      }

      that.downLoadExecl(params)
    },
    downLoadExecl (params) {
      let dateStr = ParseDate(new Date())
      // 全部下载
      if (parseInt(this.downLoadProgramme) === 1) {
        if (params.startDate && params.endDate) {
          dateStr = ParseDateForMonth(params.startDate) + '至' + ParseDateForMonth(params.endDate)
        }
      }

      const filename = '交易对账明细(' + dateStr + ').xls'
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-statement/v2/confirm/exportContractConfirmInfo', params, filename)
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.reconciliationDemo.beginUpdate
    },
    reloadList () {
      return this.$store.state.reconciliationDemo.reloadList
    },
    notAllowReconciliationQuantity () {
      return this.$store.state.reconciliationDemo.notAllowReconciliationQuantity || 0
    },
    transactionReconciliationQuery () {
      return this.$store.state.reconciliationDemo.transactionReconciliationQuery
    }
  },
  watch: {
    beginUpdate () {
      this.query.companyId = this.transactionReconciliationQuery.companyId
      this.query.startDate = this.transactionReconciliationQuery.startDate
      this.query.endDate = this.transactionReconciliationQuery.endDate
      this.query.queryString = this.transactionReconciliationQuery.queryString
      this.query.executeStatus = this.transactionReconciliationQuery.executeStatus === -1 ? null : this.transactionReconciliationQuery.executeStatus

      this.query.pageNumber = 1
      this.feeitemIds = []
      this.$store.commit('set_reconciliationDemo_notAllowReconciliationQuantity', 0)
      this.loadSummaryData()
    },
    reloadList () {
      this.loadPage()
    }
  }
}
</script>

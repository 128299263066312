import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 合同的对账列表
 * @param {Object} data
 */
export function listContractConfirmInfo (data) {
  return request({
    url: '/ooh-statement/v2/confirm/listContractConfirmInfo',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 统计合同的对账列表的总金额
 * @param {Object} data
 */
export function listContractConfirmAmount (data) {
  return request({
    url: '/ooh-statement/v2/confirm/listContractConfirmAmount',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 统计时间段合同的对账情况
 * @param {Object} data
 */
export function countContractFeeAmount (data) {
  return request({
    url: '/ooh-statement/v2/confirm/countContractFeeAmount',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 确认交易对账单
 * @param {Object} data
 */
export function confirmContractFee (data) {
  return request({
    url: '/ooh-statement/v2/confirm/confirmContractFee',
    method: 'post',
    data: qs.stringify(data)
  })
}
